import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  public set<T>(key: string, data: T): void {
    this.storage.set(key.toLowerCase(), data);
  }

  public get<T>(key: string): T {
    return this.storage.get(key.toLowerCase()) as T || null;
  }

  public clearAll() {
    this.storage.clear();
  }

  public check(key: string): boolean {
    return this.storage.has(key.toLowerCase());
  }

  public remove(key: string) {
    this.storage.remove(key.toLowerCase());
  }

  public removeLocalStorageKeys() {
    for (let key in LocalStorageKeys) {
      this.storage.remove(key.toLowerCase());
    }
  }
}

export enum LocalStorageKeys {
  FilterData = 'filterData',
  FilterDataExpiry = 'filterDataExpiry',
  CacheResetTimeStamp = 'cacheResetTimeStamp',
  User = 'user',
  Features = 'features'
}
