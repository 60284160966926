import { Inject, Injectable } from '@angular/core';
import { MsalService, MSAL_CONFIG } from '@azure/msal-angular';
import { AuthError, Configuration, InteractionRequiredAuthError } from 'msal';
import { Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenAutoRenewalService {

  private activeTimer: Subscription;
  // allows us to renew the token a little ahead of expiry
  // Msal by default has an offset of 5 minutes, so attempting to renew at 4 minutes should work
  private renewalOffset: number = 4 * 60 * 1000;

  constructor(
    @Inject(MSAL_CONFIG) private msalConfig: Configuration,
    private readonly msalService: MsalService
  ) { }

  public setupTokenRenewal(tokenExpiry: Date): void {
    if (this.activeTimer) {
      this.activeTimer.unsubscribe();
    }

    const currentTime = new Date().getTime();
    const expiryTime = tokenExpiry.getTime();
    const timeLeft = expiryTime - currentTime;

    this.activeTimer = timer(timeLeft - this.renewalOffset).subscribe(() => this.renewToken());
  }

  private renewToken() {
    this.msalService.acquireTokenSilent({
      scopes: [this.msalConfig.auth.clientId]
    })
      .catch((error: AuthError) => {
        if (InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
          this.msalService.getLogger().info("Interaction required error in MSAL Guard, prompting for interaction.");
          return this.msalService.loginRedirect();
        }

        this.msalService.getLogger().error(`Non-interaction error in token auto renewal service: ${error.errorMessage}`);
        throw error;
      });
  }
}
