import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) { }


  public set(key: string, data: any): void {
    this.sessionStorage.set(key.toLowerCase(), data);
  }

  public get(key: string): any {
    return this.sessionStorage.get(key.toLowerCase()) || null;
  }

  public clearAll() {
    this.sessionStorage.clear();
  }

  public check(key: string): boolean {
    return this.sessionStorage.has(key.toLowerCase());
  }

  public remove(key: string) {
    this.sessionStorage.remove(key.toLowerCase());
  }
}

export enum SessionStorageKeys {
  SearchExpandedOnResults = 'searchExpandedOnResults',
  QuotesExpandedOnSearch = 'quotesExpandedOnSearch',
  QuotesExpandedOnResults = 'quotesExpandedOnResults',
  QuotesExpandedOnQuote = 'quotesExpandedOnQuote',
  MainPaneScrollPosition = 'mainPaneScrollPosition',
  RestoreNavigationId = 'restoreNavigationId',
  PreviousUrl = 'previousUrl',
  CurrentUrl = 'currentUrl',
  NavigationId = 'navigationId',
  ExpandedModelIds = 'expandedModelIds',
  LastQuote = 'lastQuote',
  LastError = 'lastError',
  QuoteView = 'quoteView',
  FundingArrangements = 'fundingArrangements',
  RentalProfiles = 'rentalProfiles',
  QuoteConfigurationChoiceAvailabilities = 'quoteConfigurationChoiceAvailabilities',
  FuelCards = 'fuelCards',
  PayrollFrequencies = 'payrollfrequencies'
}
