<div class="mat-elevation-z8" #TABLE>
  <table mat-table [dataSource]="dataSource" #table
         matSort matSortActive="unprocessed" matSortDirection="desc">
    <tr style="display:none!important">
      <ng-container matColumnDef="system">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> System </th>
        <td mat-cell *matCellDef="let element"> {{element.system}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
      </ng-container>

      <ng-container matColumnDef="unprocessed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unprocessed </th>
        <td mat-cell *matCellDef="let element"> {{element.unprocessed}} </td>
        <td mat-footer-cell *matFooterCellDef> {{unprocessedTotal}} </td>
      </ng-container>

      <ng-container matColumnDef="sent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sent </th>
        <td mat-cell *matCellDef="let element"> {{element.sent}} </td>
        <td mat-footer-cell *matFooterCellDef> {{sentTotal}} </td>
      </ng-container>

      <ng-container matColumnDef="failedOnce">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Failed once </th>
        <td mat-cell *matCellDef="let element"> {{element.failedOnce}} </td>
        <td mat-footer-cell *matFooterCellDef> {{failedOnceTotal}} </td>
      </ng-container>

      <ng-container matColumnDef="deferred">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Deferred </th>
        <td mat-cell *matCellDef="let element"> {{element.deferred}} </td>
        <td mat-footer-cell *matFooterCellDef> {{deferredTotal}} </td>
      </ng-container>

      <ng-container matColumnDef="error">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Error </th>
        <td mat-cell *matCellDef="let element"> {{element.error}} </td>
        <td mat-footer-cell *matFooterCellDef> {{errorTotal}} </td>
      </ng-container>

      <ng-container matColumnDef="attachments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Attachments </th>
        <td mat-cell *matCellDef="let element"> {{element.attachments}} </td>
        <td mat-footer-cell *matFooterCellDef> {{attachmentsTotal}} </td>
      </ng-container>
    </tr>
    <tr mat-header-row *matHeaderRowDef="reportColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: reportColumns;"></tr>

    <tr mat-footer-row *matFooterRowDef="reportColumns"></tr>
  </table>

  <mat-toolbar>
    <mat-toolbar-row>
      <mat-icon (click)="downloadReport()" matTooltip="Download as Excel">file_download</mat-icon>
      <span class="spacer"></span>
      <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 20, 25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
