import { AzureRole } from "./azure-role";

export class Feature {
  featureId: number;
  name: string;
  description: string;
  isEnabled: boolean;
  isEnabledGlobal: boolean;
  createdDate: Date;
  isSelected?: boolean; // This is for UI manipulation only
}

export class FeatureAzureRole extends Feature {
  azureRoles: AzureRole[];
}

export enum FeatureItem {
  Logs = 'Logs',
  Rules = 'Rules',
  Reports = 'Reports',
  Admin = 'Admin',
  QueueManagement = 'QueueManagement',
  SystemManagement = 'SystemManagement'
}
