<div class="dashboard" *ngIf="canAccessManagement">
  <div class="title">Management | Systems</div>

  <div class="mat-elevation-z8 margin-bottom">
    <table mat-table matSort [dataSource]="dataSource" class="full-width">
      <tr style="display:none!important">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="isEnabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </th>
          <td mat-cell *matCellDef="let element">{{element.isEnabled}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="callbackUrl">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Callback url </th>
          <td mat-cell *matCellDef="let element">{{element.callbackUrl}}</td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created date </th>
          <td mat-cell *matCellDef="let element">{{element.createdDate | date:'d/MM/yyyy hh:mm:ss a'}}</td>
        </ng-container>

        <ng-container matColumnDef="updatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated date </th>
          <td mat-cell *matCellDef="let element">{{element.updatedDate | date:'d/MM/yyyy hh:mm:ss a'}}</td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.canEdit">
              <a [routerLink]="['/admin/system', element.id]">Edit</a>
            </div>
          </td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'status-enable': row.isEnabled, 'status-disabled': !row.isEnabled}"></tr>
    </table>
  </div>

  <div class="space-commands">
    <button mat-button class="btn-nav" (click)="addSystem()">Add system</button>
  </div>
</div>
