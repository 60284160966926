import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/config/app-configuration';
import { Injectable } from '@angular/core';
import { RuntimeService } from '../../services/runtime.service';
import { EmailSystemsData } from '../../models/email-systems-data';
import { EmailSystemsForEditData } from '../../models/email-systems-for-edit-data';
import { EmailSystemEditData } from '../../models/email-systems-edit-data';
import { EmailSystemForEdit } from '../../models/email-system-for-edit';
import { QueueStatusData } from '../../models/queue-status-data';
import { QueueStatus } from '../../models/queue-status';
import { ApiVersionData } from '../../models/api-version-data';
import { ResubmitDlqData } from '../../models/resubmit-dlq-data';
import { DlqData } from '../../models/dlq-data';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  managementUrl: string;
  constructor(private appConfig: AppConfiguration,
    private runtime: RuntimeService,
    private http: HttpClient) {
    this.managementUrl = `${this.appConfig?.Configuration?.EmailServiceApiUrl}/v1.0/management`;
  }

  getSystems() {
    return this.http.get<EmailSystemsData>(
      `${this.managementUrl}/systems`,
      this.runtime.getHttpOptions());
  }

  getSystemsForEdit() {
    return this.http.get<EmailSystemsForEditData>(
      `${this.managementUrl}/systems/edit`,
      this.runtime.getHttpOptions());
  }

  getSystem(id: number) {
    return this.http.get<EmailSystemEditData>(
      `${this.managementUrl}/systems/edit/${id}`,
      this.runtime.getHttpOptions());
  }

  addSystem(system: EmailSystemForEdit) {
    return this.http.post<EmailSystemEditData>(
      `${this.managementUrl}/systems`,
      JSON.stringify(system),
      this.runtime.getHttpOptions());
  }

  updateSystem(system: EmailSystemForEdit) {
    return this.http.put<EmailSystemEditData>(
      `${this.managementUrl}/systems/edit/${system.id}`,
      JSON.stringify(system),
      this.runtime.getHttpOptions());
  }

  getQueueStatus() {
    return this.http.get<QueueStatusData>(
      `${this.managementUrl}/queue-status`,
      this.runtime.getHttpOptions());
  }

  peekDlq(queue: string) {
    return this.http.post<DlqData>(
      `${this.managementUrl}/peek-dlq`,
      JSON.stringify({ queue: queue, count: 20 }),
      this.runtime.getHttpOptions());
  }

  resumitDlqMessages(queue: string, sequenceNumbers: number[]) {
    return this.http.post<ResubmitDlqData>(
      `${this.managementUrl}/resubmit-dlq-message`,
      JSON.stringify({ queue: queue, sequenceNumbers: sequenceNumbers }),
      this.runtime.getHttpOptions());
  }

  changeQueueStatus(queue: QueueStatus) {
    return this.http.put<QueueStatusData>(
      `${this.managementUrl}/queue-status`,
      JSON.stringify(queue),
      this.runtime.getHttpOptions());
  }

  getApiVersion() {
    return this.http.get<ApiVersionData>(
      `${this.managementUrl}/Version`,
      this.runtime.getHttpOptions()
    );
  }

}
