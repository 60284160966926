<div [class]="theme" class="margin-bottom">
  <app-nav-bar></app-nav-bar>

  <mat-toolbar class="commandbar">
    <a routerLink="/app" class="command-button" routerLinkActive="active">Recent emails</a>
    <a routerLink="/search" class="command-button" routerLinkActive="active">Search emails</a>
  </mat-toolbar>
  <div class="container-main">
    <router-outlet></router-outlet>
  </div>
</div>
