<div class="dashboard" *ngIf="canAccessManagement">
  <div class="title">Management | Queues</div>

  <div class="mat-elevation-z8 margin-bottom">
    <table mat-table matSort [dataSource]="dataSource" class="full-width">
      <tr style="display:none!important">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/admin/dlq', element.name]" *ngIf="element.deadLetterMessageCount > 0">{{element.name}}</a>
            <span *ngIf="element.deadLetterMessageCount === 0">{{element.name}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-select [(ngModel)]="element.status" (selectionChange)="changeStatus(element)">
              <mat-option [value]="stat.id" *ngFor="let stat of statusList">
                {{ stat.name }}
              </mat-option>
            </mat-select>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="messageCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Messages </th>
          <td mat-cell *matCellDef="let element">{{element.messageCount}}</td>
          <td mat-footer-cell *matFooterCellDef> {{messageCountTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="activeMessageCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Active # </th>
          <td mat-cell *matCellDef="let element">{{element.activeMessageCount}}</td>
          <td mat-footer-cell *matFooterCellDef> {{activeMessageCountTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="deadLetterMessageCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Deadletters </th>
          <td mat-cell *matCellDef="let element">{{element.deadLetterMessageCount}}</td>
          <td mat-footer-cell *matFooterCellDef> {{deadLetterMessageCountTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="scheduledMessageCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Scheduled # </th>
          <td mat-cell *matCellDef="let element">{{element.scheduledMessageCount}}</td>
          <td mat-footer-cell *matFooterCellDef> {{scheduledMessageCountTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="transferDeadLetterMessageCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Transfer Deadletter # </th>
          <td mat-cell *matCellDef="let element">{{element.transferDeadLetterMessageCount}}</td>
          <td mat-footer-cell *matFooterCellDef> {{transferDeadLetterMessageCountTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="transferMessageCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Transfer # </th>
          <td mat-cell *matCellDef="let element">{{element.transferMessageCount}}</td>
          <td mat-footer-cell *matFooterCellDef> {{transferMessageCountTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="sizeInBytes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
          <td mat-cell *matCellDef="let element">{{element.sizeInBytes | fileSize}}</td>
          <td mat-footer-cell *matFooterCellDef> {{sizeInBytesTotal | fileSize}} </td>
        </ng-container>

        <ng-container matColumnDef="defaultMessageTimeToLive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Default Message TTL </th>
          <td mat-cell *matCellDef="let element">{{element.defaultMessageTimeToLive * 1000 | date:'dd hh:mm:ss'}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!--<ng-container matColumnDef="enableDeadLetteringOnMessageExpiration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enable deadlettering on expiration </th>
          <td mat-cell *matCellDef="let element">{{element.enableDeadLetteringOnMessageExpiration}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>-->
        <!--<ng-container matColumnDef="autoDeleteOnIdle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Auto delete on idle </th>
          <td mat-cell *matCellDef="let element">{{element.autoDeleteOnIdle * 1000 | date:'hh:mm:ss'}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>-->

        <ng-container matColumnDef="maxDeliveryCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>de Max delivery count </th>
          <td mat-cell *matCellDef="let element">{{element.maxDeliveryCount}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="maxSizeInMB">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Max size (MB) </th>
          <td mat-cell *matCellDef="let element">{{element.maxSizeInMB}}</td>
          <td mat-footer-cell *matFooterCellDef> {{maxSizeInMBTotal}} </td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'queue-status-active': row.status === 'Active', 'queue-status-disabled': row.status === 'Disabled', 'queue-status-sendDisabled': row.status === 'SendDisabled', 'queue-status-receiveDisabled': row.status === 'ReceiveDisabled' }"></tr>

      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>

  <div class="space-commands">
    <button mat-button class="btn-nav" (click)="loadQueueStatus()">Reload</button>
  </div>
</div>
