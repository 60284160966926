export abstract class AppConfiguration {
  Configuration: {
    ApplicationName: string;
    ApplicationTitle: string;
    EmailServiceApiUrl: string;
    AzureSettings: {
      AppClientId: string;
      DirTenantId: string;
      RedirectUri: string;
    };
    HeaderEnvText: string;
  };
}
