import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { MessageMeta } from "../../../models/message-meta";
import { SelectionModel } from '@angular/cdk/collections';
import { FeatureItem } from "../../models/feature";
import { FeatureService } from '../../services/feature.service';
import { ManagementService } from "../../services/management.service";

@Component({
  selector: 'dlq-management',
  templateUrl: './dlq-management.component.html',
  styleUrls: ['./dlq-management.component.scss']
})
export class DlqManagementComponent implements OnInit {
  displayedColumns: string[] = ['select', 'messageId', 'sequenceNumber', 'deliveryCount', 'senderAddress', 'receiverAddress', 'subject', 'enqueuedTimeUtc', 'correlationId', 'size'];
  dataSource: MatTableDataSource<MessageMeta>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  queue: string = ''
  selection = new SelectionModel<MessageMeta>(true, []);
  messages: MessageMeta[];
  canAccessManagement = false;

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private featureService: FeatureService,
    private managementService: ManagementService) {
  }

  ngOnInit() {
    this.canAccessManagement = this.featureService?.canAccess(FeatureItem.QueueManagement);

    this.route.paramMap.subscribe(x => {
      this.queue = x.get('queue');
      this.loadQueueData();
    });
  }

  loadQueueData() {
    this.managementService.peekDlq(this.queue).subscribe(r => {
      if (r.success) {
        this.messages = r.messages;
        this.dataSource = new MatTableDataSource<MessageMeta>(r.messages);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.snackBar.open(r.message, 'Ok', { duration: 3000 });
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  resubmit() {
    const selectedNumbers = this.selection.selected.map(x => x.sequenceNumber).sort(((a, b) => a - b));
    for (let i = 0; i < selectedNumbers.length; i++) {
      if (this.messages[i].sequenceNumber !== selectedNumbers[i]) {
        this.snackBar.open(`The selected messages must respect FIFO order.`, 'Ok', { duration: 3000 });
        return;
      }
    }
    this.managementService.resumitDlqMessages(this.queue, selectedNumbers).subscribe(x => {
      this.snackBar.open(x.success ? `${x.result.length} DLQ messages resubmitted.` : x.message, 'Ok', { duration: 3000 });
      if (x.success) {
        this.loadQueueData();
      }
    });
  }
}
