import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number = 0, precision: number = 2): string {
    // tslint:disable-next-line:curly
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    const KB = 1024;
    let unit = 0;

    while (bytes >= KB) {
      bytes /= KB;
      unit++;
    }

    if (unit == 0)
      precision = 0;

    return bytes.toFixed(+precision) + ' ' + this.units[unit];
  }
}
