import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { EmailListComponent } from './email-list/email-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppEmailComponent } from './app-email/app-email.component';
import { VersionComponent } from './version/version.component';
import { ReportMainPaneComponent } from './reports/components/report-main-pane/report-main-pane.component';
import { ServiceManagementComponent } from './admin/components/service-management/service-management.component';
import { DlqManagementComponent } from './admin/components/dlq-management/dlq-management.component';
import { SystemEditComponent } from './admin/components/system-edit/system-edit.component';
import { SystemsComponent } from './admin/components/systems/systems.component';
import { RuleEditComponent } from './email-rule/components/rule-edit/rule-edit.component';
import { RulesComponent } from './email-rule/components/rules/rules.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

const routes: Routes = [
  { path: 'app', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'app/:count', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'app-email/:uniqueId', component: AppEmailComponent, canActivate: [MsalGuard] },
  { path: 'search', component: EmailListComponent, canActivate: [MsalGuard] },
  { path: 'version', component: VersionComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'reports', component: ReportMainPaneComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'admin/service', component: ServiceManagementComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'admin/dlq/:queue', component: DlqManagementComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'admin/systems', component: SystemsComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'admin/system', component: SystemEditComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'admin/system/:id', component: SystemEditComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'rules', component: RulesComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'rule', component: RuleEditComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'rule/:id', component: RuleEditComponent, children: [], canActivate: [MsalGuard], pathMatch: 'full' },
  { path: 'access-denied', component: AccessDeniedComponent, children: [], pathMatch: 'full' },
  { path: '**', redirectTo: 'app' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
