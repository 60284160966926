import { Feature } from "./feature";

export class AzureRole {
  azureRoleId: number;
  name: string;
  isEnabled: boolean;
  isAdmin: boolean;
  createdDate: Date;
  isSelected?: boolean; // This is for UI manipulation only
}

export class AzureRoleFeature extends AzureRole {
  features: Feature[];
}

export class AzureRoleFeatureMap {
  azureRoleId: number;
  featureId: number;
}

export enum AzureRoleItem {
  Admin = 'Admin',
  Reader = 'Reader',
  ReportViewer = 'ReportViewer',
  SuperUser = 'SuperUser'
}
