<div class="dashboard" *ngIf="canAccessManagement">
  <div class="title">Management | DLQ messages</div>

  <div class="mat-elevation-z8 margin-bottom">
    <table mat-table matSort [dataSource]="dataSource" class="full-width">
      <tr style="display:none!important">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="messageId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Message id </th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/app-email', element.uniqueId]" *ngIf="element.uniqueId" href="" target="_blank">{{element.uniqueId}}</a>
            <span *ngIf="!element.uniqueId">{{element.messageId}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="sequenceNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sequence # </th>
          <td mat-cell *matCellDef="let element">{{element.sequenceNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="deliveryCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery count </th>
          <td mat-cell *matCellDef="let element">{{element.deliveryCount}}</td>
        </ng-container>

        <ng-container matColumnDef="senderAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sender </th>
          <td mat-cell *matCellDef="let element">{{element.senderAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="receiverAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Receiver </th>
          <td mat-cell *matCellDef="let element">{{element.receiverAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject </th>
          <td mat-cell *matCellDef="let element">{{element.subject}}</td>
        </ng-container>

        <ng-container matColumnDef="enqueuedTimeUtc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enqueued time UTC </th>
          <td mat-cell *matCellDef="let element">{{element.enqueuedTimeUtc | date:'d/MM/yyyy hh:mm:ss a'}}</td>
        </ng-container>

        <ng-container matColumnDef="correlationId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlation Id </th>
          <td mat-cell *matCellDef="let element">{{element.correlationId}}</td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
          <td mat-cell *matCellDef="let element">{{element.size | fileSize}}</td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

  <div class="space-commands">
    <button mat-button class="btn-nav" (click)="resubmit()" [disabled]="selection.selected.length === 0">Resubmit</button>
  </div>

</div>
