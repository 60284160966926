import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../shared/material.module";
import { ServiceManagementComponent } from './components/service-management/service-management.component';
import { DlqManagementComponent } from './components/dlq-management/dlq-management.component';
import { UtilsModule } from '../utils/utils.module';
import { RouterModule } from '@angular/router';
import { SystemsComponent } from "./components/systems/systems.component";
import { SystemEditComponent } from "./components/system-edit/system-edit.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
	declarations: [
    ServiceManagementComponent,
    SystemsComponent,
    SystemEditComponent,
    DlqManagementComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
    UtilsModule,
    MaterialModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule
	],
	exports: [
    ServiceManagementComponent,
    SystemsComponent,
    SystemEditComponent,
    DlqManagementComponent
	],
	providers: [
  ]
})
export class AdminModule { }
