<div *ngIf="!userName">
    User session not valid. Please refresh the page. If the problem persists, please contact the administrator.
</div>

<div *ngIf="!canAccessReports">
    The page you are trying to access does not exist or moved.
</div>

<div class="dashboard" *ngIf="canAccessReports">
    <div class="title">Report | Email stats</div>

    <div class="toolbar">
        <div class="tbChild filterItem">
            <div class="fillWidth">
                <mat-form-field class="fillWidth">
                    <mat-label>Filter</mat-label>
                    <input class="filter" matInput (keyup)="applyFilter($event)" placeholder="Type any text to search" #input>
                </mat-form-field>
            </div>
        </div>
        <div class="tbChild">
            <div class="dateItem">
                <mat-form-field class="dateField">
                    <mat-label>From date</mat-label>
                    <input matInput name="dtFrom" 
                        (dateChange)="onDateChange()"
                        [matDatepicker]="pkFrom" [formControl]="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="pkFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pkFrom></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="dateItem">
                <mat-form-field class="dateField">
                    <mat-label>To date</mat-label>
                    <input matInput name="dtTo" 
                        (dateChange)="onDateChange()"
                        [matDatepicker]="pkTo" [formControl]="toDate">
                    <mat-datepicker-toggle matSuffix [for]="pkTo"></mat-datepicker-toggle>
                    <mat-datepicker #pkTo></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="action">
                <button mat-raised-button color="primary" (click)="reloadReports()">
                    Reload
                </button>
            </div>
        </div>
    </div>
    <div class="content">
        <app-email-stats-report [fromDate]="formattedFromDate" [toDate]="formattedToDate" [filter]="filterValue" [reloadTick]="reloadTick">
        </app-email-stats-report>
    </div>
</div>
