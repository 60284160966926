import { Injectable } from "@angular/core";
import { Feature, FeatureAzureRole } from "../models/feature";
import { User } from "../models/user";
import { LocalStorageKeys, LocalStorageService } from "../../services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  user: User;
  isAdmin = false;

  features: Feature[];

  constructor(private localStorageService: LocalStorageService) {
    this.reloadSettings();
  }

  reloadSettings() {
    this.user = this.localStorageService.get<User>(LocalStorageKeys.User);
    this.features = this.localStorageService.get<Feature[]>(LocalStorageKeys.Features);

    // the user is an administrator if the User's isAdmin property is true
    // or any one of the AzureRoles' isAdmin property is true
    this.isAdmin = (
      // This following line is commented so not to use the isAdminFlad in the user object
      //this.user?.isAdmin === true || 
      this.user?.azureRolesFeatures?.find(
        i => i.isEnabled === true && i.isAdmin === true)?.isAdmin === true);
  }

  canAccess(featureName: string): boolean {
    this.reloadSettings();

    if (this.isAdmin)
      return true;

    // Either if the user is an Admin or the AzureRole is enabled 
    // & the feature within the AzureRole must be either enabled or enabled for all.

    let hasAccess;
    let feature = this.features?.find(f => f.name && f.name !== '' && f.name.toLowerCase() === featureName.toLowerCase());
    if (feature?.isEnabled === false) {
      // Feature either doesn't exist or is not enabled.
      hasAccess = false;
    } else if (feature?.isEnabledGlobal === true) {
      // No need to check for roles. This feature is enabled for everyone globally.
      hasAccess = true;
    } else {
      // Check for access in the roles
      hasAccess = this.user?.azureRolesFeatures?.find(
        ar =>
          ar.isEnabled === true
          && (ar.isAdmin
            || (feature as FeatureAzureRole).azureRoles.find(a => a.name.toLowerCase() === ar.name.toLowerCase()))
      ) != undefined;
    }

    return hasAccess;
    // READ THE COMMENTS ON MODIFYING THIS METHOD.
  }
}
