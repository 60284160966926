<div class="dashboard" *ngIf="canAccessRules">
  <div class="title">Management | Rule</div>

  <div class="parent">
    <div class="mat-form-field margin-top">
      <mat-label class="margin-right">Enabled</mat-label>
      <mat-checkbox color="primary" [(ngModel)]="rule.isEnabled">
      </mat-checkbox>
    </div>
    <mat-form-field class="margin-top">
      <mat-label>Sender address</mat-label>
      <input matInput placeholder="Sender address to match" [(ngModel)]="rule.senderAddress" name="senderAddress">
    </mat-form-field>
    <mat-form-field class="margin-top">
      <mat-label>Receiver address</mat-label>
      <input matInput placeholder="Receiver address to match" [(ngModel)]="rule.receiverAddress" name="receiverAddress">
    </mat-form-field>
    <mat-form-field class="margin-top">
      <mat-label>CC address</mat-label>
      <input matInput placeholder="CC address to match" [(ngModel)]="rule.ccAddress" name="ccAddress">
    </mat-form-field>
    <mat-form-field class="margin-top">
      <mat-label>Subject</mat-label>
      <input matInput placeholder="Subject to match" [(ngModel)]="rule.subject" name="subject">
    </mat-form-field>
    <mat-form-field class="margin-top">
      <mat-label>Body</mat-label>
      <input matInput placeholder="Body to match" [(ngModel)]="rule.body" name="body">
    </mat-form-field>
    <mat-form-field class="margin-top">
      <mat-label>System</mat-label>
      <mat-select [(value)]="rule.systemId">
        <mat-option *ngFor="let system of systems" [value]="system.id">
          {{system.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="margin-top">
      <mat-label>Priority</mat-label>
      <input matInput type="number" placeholder="Priority" [(ngModel)]="rule.priority" name="priority">
    </mat-form-field>
    <mat-form-field class="margin-top">
      <mat-label>Redirect address</mat-label>
      <input matInput placeholder="Your address to receive the email" [(ngModel)]="rule.redirectAddress" name="redirectAddress">
    </mat-form-field>
  </div>

  <div class="space-commands">
    <button mat-button class="btn-nav" (click)="save()">Save</button>
  </div>
</div>
