import { Component, Input, OnInit } from "@angular/core";
import { Version } from "src/environments/version";
import { ManagementService } from "../admin/services/management.service";

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  @Input() versionDialogMode = false;
  Version = Version;
  apiVer: string;
  apiRev: string;

  constructor(private managementService: ManagementService) { }

  ngOnInit(): void {
    this.managementService.getApiVersion().subscribe(result => {
      if (result && result.success) {
        const parts = result.version.split('+');
        this.apiVer = parts[0];
        this.apiRev = (parts.length > 1) ? parts[1].substring(0, 8) : '-';
      }
    }, error => { });
  }
}
