import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RuntimeService {
  private _token: string;

  constructor() { }

  public set token(value: string) {
    this._token = value;
  }

  public get token(): string {
    return this._token;
  }

  public getHttpOptions() {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.token)
    };
  }
}
