import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportMainPaneComponent } from './components/report-main-pane/report-main-pane.component';
import { EmailStatsReportComponent } from './components/email-stats-report/email-stats-report.component';
import { MaterialModule } from '../shared/material.module';

@NgModule({
  declarations: [
    ReportMainPaneComponent,
    EmailStatsReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports:[
    ReportMainPaneComponent,
    EmailStatsReportComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-AU'
    },
    MatDatepickerModule,
    MatNativeDateModule,
  ]
})
export class ReportModule { }
