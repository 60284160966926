<form>
  <mat-form-field appearance="fill" class="form-input">
    <mat-label>System</mat-label>
    <mat-select [(value)]="selectedSystem">
      <mat-option *ngFor="let system of systems" [value]="system.id">
        {{system.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="form-input">
    <mat-label>Receiver Address</mat-label>
    <input matInput placeholder="Receiver Address" [(ngModel)]="receiverAddress" name="receiverAddress">
  </mat-form-field>
  <mat-form-field class="form-input">
    <mat-label>Sender Address</mat-label>
    <input matInput placeholder="" [(ngModel)]="senderAddress" name="senderAddress">
  </mat-form-field>
  <mat-form-field class="form-input">
    <mat-label>Reference id</mat-label>
    <input matInput placeholder="" [(ngModel)]="referenceId" name="referenceId">
  </mat-form-field>
  <!--<mat-form-field class="form-input">
    <mat-label>Subject</mat-label>
    <input matInput placeholder="" [(ngModel)]="subject" name="subject">
  </mat-form-field>-->
  <button mat-raised-button class="btn-nav" (click)="search()">Find</button>
</form>
<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">
    <tr style="display:none!important">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell *matCellDef="let element"><a [routerLink]="['/app-email', element.uniqueId]">{{element.id}}</a></td>
      </ng-container>

      <ng-container matColumnDef="system">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> System </th>
        <td mat-cell *matCellDef="let element"> {{element.system}} </td>
      </ng-container>

      <ng-container matColumnDef="senderName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sender </th>
        <td mat-cell *matCellDef="let element">{{element.senderName}}</td>
      </ng-container>

      <ng-container matColumnDef="senderAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sender Address</th>
        <td mat-cell *matCellDef="let element">{{element.senderAddress}}</td>
      </ng-container>

      <ng-container matColumnDef="receiverName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Receiver </th>
        <td mat-cell *matCellDef="let element">{{element.receiverName}}</td>
      </ng-container>

      <ng-container matColumnDef="receiverAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Receiver Address</th>
        <td mat-cell *matCellDef="let element" class="email-address-list">{{element.receiverAddress}}</td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject </th>
        <td mat-cell *matCellDef="let element">{{element.subject}}</td>
      </ng-container>

      <ng-container matColumnDef="referenceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Reference </th>
        <td mat-cell *matCellDef="let element">{{element.referenceId}}</td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
        <td mat-cell *matCellDef="let element"> {{element.createdDateLocal}} </td>
      </ng-container>

      <ng-container matColumnDef="attachmentCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Attachments </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.attachmentCount > 0">
            <i class="material-icons">attach_file</i>
            <span class="icon-text">{{element.attachmentCount}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'status-sent': row.status === 'Sent', 'status-unprocessed': row.status === 'Unprocessed ', 'status-error': row.status === 'Error', 'status-failedOnce': row.status === 'FailedOnce' }"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
