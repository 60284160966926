import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionStorageService, SessionStorageKeys } from './../services/session-storage.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorInfo } from '../models/error-info';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  detailedInfo: HttpErrorResponse;
  showJSON = false;
  errorTitle: string;
  errorMessage: string;
  isValidationError: boolean;
  validationErrors: string[];

  constructor(private sessionStorageService: SessionStorageService,
              @Inject(MAT_DIALOG_DATA) public data: ErrorInfo) { }

  ngOnInit() {
    const isErrorMessageLong = this.data?.reason?.length > 100;
    this.errorTitle = isErrorMessageLong ? this.data.reason.substr(0, 100) + '...' : this.data?.reason || '';
    this.isValidationError = this.data?.isValidationError || false;
    this.validationErrors = this.data?.validationErrors || [];
    this.detailedInfo = this.sessionStorageService.get(SessionStorageKeys.LastError);
    // if data.reason is same as detailedInfo.error, do not display twice
    if (this.data?.reason !== this.detailedInfo?.error && isErrorMessageLong) {
      this.errorMessage = this.data.reason;
    }
  }

  toggleError() {
    this.showJSON = !this.showJSON;
  }

  closeDialog() {
    this.sessionStorageService.remove(SessionStorageKeys.LastError);
  }
}
