import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/config/app-configuration';
import { Injectable } from '@angular/core';
import { RuntimeService } from '../../services/runtime.service';
import { EmailRulesData } from '../models/email-rules-data';
import { EmailRuleData } from '../models/email-rule-data';
import { EmailRule } from '../models/email-rule';

@Injectable({
  providedIn: 'root'
})
export class RuleService {
  managementUrl: string;
  constructor(private appConfig: AppConfiguration,
    private runtime: RuntimeService,
    private http: HttpClient) {
    this.managementUrl = `${this.appConfig?.Configuration?.EmailServiceApiUrl}/v1.0/management`;
  }

  getRules() {
    return this.http.get<EmailRulesData>(
      `${this.managementUrl}/rules`,
      this.runtime.getHttpOptions());
  }

  getRule(id: string) {
    return this.http.get<EmailRuleData>(
      `${this.managementUrl}/rules/${id}`,
      this.runtime.getHttpOptions());
  }

  addRule(rule: EmailRule) {
    return this.http.post<EmailRuleData>(
      `${this.managementUrl}/rules`,
      JSON.stringify(rule),
      this.runtime.getHttpOptions());
  }

  updateRule(rule: EmailRule) {
    return this.http.put<EmailRuleData>(
      `${this.managementUrl}/rules/${rule.id}`,
      JSON.stringify(rule),
      this.runtime.getHttpOptions());
  }

  deleteRule(id: string) {
    return this.http.delete<EmailRuleData>(
      `${this.managementUrl}/rules/${id}`,
      this.runtime.getHttpOptions());
  }
}
