import { Injectable } from '@angular/core';
import { AppConfiguration } from 'src/config/app-configuration';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JsonAppConfigService extends AppConfiguration {
  constructor(private http: HttpClient) {
    super();
  }

  load() {
    return this.http.get<AppConfiguration>('app.config.json')
      .toPromise()
      .then(data => {
        this.Configuration = data.Configuration;
      })
      .catch((error) => {
        console.error(error, 'Could not load configuration');
      });
  }
}
