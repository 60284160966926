import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../shared/material.module";
import { UtilsModule } from '../utils/utils.module';
import { RouterModule } from '@angular/router';
import { RulesComponent } from "./components/rules/rules.component";
import { RuleEditComponent } from "./components/rule-edit/rule-edit.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
	declarations: [
    RulesComponent,
    RuleEditComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
    UtilsModule,
    MaterialModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule
	],
	exports: [
    RulesComponent,
    RuleEditComponent
	],
	providers: [
  ]
})
export class RuleModule { }
