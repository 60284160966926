import { SafeUrl } from '@angular/platform-browser';
import { Component, OnInit, LOCALE_ID, Inject, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailService } from '../services/email.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Email } from '../models/email';
import { forkJoin } from 'rxjs';
import { EmailAttachment } from '../models/email-attachment';
import { EmailData } from '../models/email-data';
import { EmailAttachmentData } from '../models/email-attachment-data';
import { EmailBodyData } from '../models/email-body';
import { FeatureItem } from '../admin/models/feature';
import { FeatureService } from '../admin/services/feature.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EmailLog } from '../models/email-log';
import { ResendEmailData } from '../models/resend-email-data';

@Component({
  selector: 'app-email',
  templateUrl: './app-email.component.html',
  styleUrls: ['./app-email.component.scss']
})

export class AppEmailComponent implements OnInit {
  displayedColumns: string[] = ['id', 'reason', 'status', 'correlationId', 'createdDate'];
  dataSource: MatTableDataSource<EmailLog>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  attachments: EmailAttachment[] = [];
  canAccessLogs: boolean = false;
  canResend: boolean = false;
  result: {
    email: Email,
    body: SafeUrl,
    logs: EmailLog[],
    formattedSender?: string;
    formattedReceiver?: string;
    formattedReply?: string;
    formattedCC?: string;
    formattedBCC?: string;
  } = {
      email: {} as Email,
      body: {},
      logs: []
    };

  constructor(
    private snackBar: MatSnackBar,
    private emailService: EmailService,
    private route: ActivatedRoute,
    private router: Router,
    private featureService: FeatureService,
    @Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(x => {
      const uniqueId = x.get('uniqueId');

      if (uniqueId !== '') {
        this.canAccessLogs = this.featureService?.canAccess(FeatureItem.Logs);
        forkJoin([this.emailService.getEmail(uniqueId, this.canAccessLogs),
        this.emailService.getEmailBody(uniqueId),
        this.emailService.getAttachments(uniqueId)]
        ).subscribe(([emailData, emailBody, attachmentsData]: [EmailData, EmailBodyData, EmailAttachmentData]) => {
          this.loadEmail(emailData, emailBody, attachmentsData);
        }, (error) => {
          if (error.error.statusCode === 401)
            this.router.navigate(['/app']);
          else
            this.snackBar.open(`Could not retrieve email data due to ${error.statusText || error.message}. Please try again later.`, 'Ok', { duration: 3000 });
        });
      }
    });
  }

  loadEmail(emailData: EmailData, emailBody: EmailBodyData, attachmentsData: EmailAttachmentData) {
    if (emailData.success) {
      this.result.email = emailData.email;
      this.result.formattedSender = this.formatAddress(emailData.email.senderName, emailData.email.senderAddress);
      this.result.formattedReceiver = this.formatAddress(emailData.email.receiverName, emailData.email.receiverAddress);
      this.result.formattedCC = this.formatAddress(emailData.email.ccName, emailData.email.ccAddress);
      this.result.formattedBCC = this.formatAddress(emailData.email.bccName, emailData.email.bccAddress);
      this.result.formattedReply = this.formatAddress(undefined, emailData.email.replyAddress);
      this.result.body = emailBody.body;
      this.canResend = this.result.email.status === 'Error';
    } else {
      this.snackBar.open(emailData.message, 'Ok', { duration: 3000 });
    }

    if (attachmentsData.success) {
      this.attachments = attachmentsData.attachments.attachments;
    } else {
      this.snackBar.open(attachmentsData.message, 'Ok', { duration: 3000 });
    }

    const logs = emailData.logs;
    const unknownStatus = 0;
    const statuses = new Map([
      [1, 'Unprocessed'],
      [2, 'Sent'],
      [3, 'Error'],
      [4, 'FailedOnce'],
      [5, 'Deferred'],
      [6, 'Deadletter'],
      [unknownStatus, 'Unknown']
    ]);
    for (let log of logs) {
      let status = statuses.get(log.statusId);
      if (!status) {
        status = statuses[unknownStatus];
      }
      log.status = status;
    }
    this.dataSource = new MatTableDataSource<EmailLog>(logs);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  formatAddress(name: string, address: string): string {
    let output = name;
    if (!output) {
      output = '';
    }

    if (!address) {
      return output;
    }

    const addresses = address.split(',');
    if (output === '') {
      output = this.formatAddressWithLink(address);
    } else if (address) {
      const startSeparator = addresses.length === 1 ? "<" : "(";
      const endSeparator = addresses.length === 1 ? ">" : ")";
      output = `${output} ${startSeparator}${this.formatAddressWithLink(address)}${endSeparator}`;
    }

    return output;
  }

  formatAddressWithLink(address: string): string {
    return `<a target="_blank" href="mailto:${address}">${address}</a>`;
  }

  downloadAttachment(attachment: EmailAttachment) {
    this.emailService.downloadAttachment(this.result.email.uniqueId, attachment.uniqueId).subscribe((response: any) => {
      const dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      downloadLink.setAttribute('download', attachment.name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  public trackItem(index: number, item: EmailAttachment) {
    return item.id;
  }

  home() {
    this.router.navigate(['/app']);
  }

  resend() {
    this.emailService.resend(this.result.email.uniqueId).subscribe((response: ResendEmailData) => {
      if (response.success) {
        this.snackBar.open('Email resent', 'Ok', { duration: 3000 });
      } else {
        this.snackBar.open(`Email failed to resend: ${response.message}`, 'Ok', { duration: 3000 });
      }
    });
  }
}
