import { AppConfiguration } from 'src/config/app-configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailFilter } from '../models/email-filter';
import { RuntimeService } from './runtime.service';
import { EmailData } from '../models/email-data';
import { EmailAttachmentData } from '../models/email-attachment-data';
import { EmailsData } from '../models/emails-data';
import { EmailBodyData } from '../models/email-body';
import { ResendEmailData } from '../models/resend-email-data';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  serviceUrl: string;
  constructor(private appConfig: AppConfiguration,
    private http: HttpClient,
    private runtime: RuntimeService) {
    this.serviceUrl = `${this.appConfig?.Configuration?.EmailServiceApiUrl}/v1.0/discover`;
  }

  getEmail(id: string, withLogs: boolean) {
    return this.http.get<EmailData>(
      `${this.serviceUrl}/email/${id}/${withLogs}`,
      this.runtime.getHttpOptions());
  }

  getAttachments(id: string) {
    return this.http.get<EmailAttachmentData>(
      `${this.serviceUrl}/email/${id}/attachments`,
      this.runtime.getHttpOptions());
  }

  getEmailBody(id: string) {
    return this.http.get<EmailBodyData>(
      `${this.serviceUrl}/email/${id}/body`,
      this.runtime.getHttpOptions());
  }

  getRecentEmails(filterType: string, count: number) {
    return this.http.get<EmailsData>(
      `${this.serviceUrl}/recent/${filterType}/${count}`,
      this.runtime.getHttpOptions());
  }

  downloadAttachment(emailId: string, attachmentId: string) {
    return this.http.get(
      `${this.serviceUrl}/email/${emailId}/attachment/${attachmentId}`,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + this.runtime.token)
      });
  }

  resend(emailId: string) {
    return this.http.put<ResendEmailData>(
      `${this.appConfig?.Configuration?.EmailServiceApiUrl}/v1.0/email/${emailId}/resend`,
      JSON.stringify({ emailId }),
      this.runtime.getHttpOptions());
  }

  findEmails(filter: EmailFilter) {
    return this.http.post<EmailsData>(
      `${this.serviceUrl}/search`,
      JSON.stringify(filter),
      this.runtime.getHttpOptions());
  }

  getAvatar() {
    return this.http.get(
      'https://graph.microsoft.com/v1.0/me/photo/$value',
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders()
          .set('sg_ignoreError', 'true')
          .set('Authorization', 'Bearer ' + this.runtime.token)
      });
  }
}
