import { HttpConfigInterceptor } from './services/http-config-interceptor';
import { AppConfiguration } from 'src/config/app-configuration';
import { JsonAppConfigService } from '../config/json-app-config.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { Configuration } from 'msal';
import {
  MsalAngularConfiguration, MsalGuard/*, MsalInterceptor*/, MsalModule,
  MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR
} from '@azure/msal-angular';

import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { EmailListComponent } from './email-list/email-list.component';
import { AppEmailComponent } from './app-email/app-email.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { VersionComponent } from './version/version.component';
import { VersionDialogComponent } from './version-dialog/version-dialog.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { ReportModule } from './reports/report.module';
import { AdminModule } from './admin/admin.module';
import { RuleModule } from './email-rule/email-rule.module';
import { UtilsModule } from './utils/utils.module';
import { DatePipe } from '@angular/common';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

let msalConfig: Configuration;
let msalAngularConfig: MsalAngularConfiguration;
export function MsalConfigFactory(): Configuration {
  return msalConfig;
}
export function MsalAngularConfigFactory(): MsalAngularConfiguration {
  return msalAngularConfig;
}

export function configLoader(jsonAppConfigService: JsonAppConfigService) {
  return () => {
    return jsonAppConfigService.load().then(() => {
      msalConfig = {
        auth: {
          clientId: jsonAppConfigService.Configuration.AzureSettings.AppClientId,
          authority: 'https://login.microsoftonline.com/' + jsonAppConfigService.Configuration.AzureSettings.DirTenantId,
          redirectUri: jsonAppConfigService.Configuration.AzureSettings.RedirectUri,
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: 'localStorage',
        }
      };
      msalAngularConfig = {
        popUp: false, // redirect to login page instead of showin a popup window because by default chrome may block the popups
        consentScopes: [
          'User.Read', 'profile'
        ],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
          [jsonAppConfigService.Configuration.EmailServiceApiUrl, [`api://${jsonAppConfigService?.Configuration?.AzureSettings.AppClientId}/EmailService.Access`]],
        ]
      };
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EmailListComponent,
    ErrorPageComponent,
    AppEmailComponent,
    NavBarComponent,
    VersionComponent,
    VersionDialogComponent,
    AccessDeniedComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatChipsModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxJsonViewerModule,
    StorageServiceModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMenuModule,
    MsalModule,
    AppRoutingModule,
    ReportModule,
    UtilsModule,
    AdminModule,
    RuleModule
  ],
  providers: [
    CookieService,
    MsalGuard,
    DatePipe,
    MsalService,
    //{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {
      provide: AppConfiguration,
      deps: [HttpClient],
      useExisting: JsonAppConfigService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configLoader,
      deps: [JsonAppConfigService],
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MsalConfigFactory,
      deps: [JsonAppConfigService]
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MsalAngularConfigFactory,
      deps: [JsonAppConfigService]
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-AU'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
