import { EmailStats } from '../../models/email-stats';
import { Subscription } from 'rxjs';
import { ReportingService } from '../../services/reporting.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy, ViewChild, AfterViewInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { EmailStatsData } from '../../models/email-stats-data';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-email-stats-report',
  templateUrl: './email-stats-report.component.html',
  styleUrls: ['./email-stats-report.component.scss']
})
export class EmailStatsReportComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() filter: string;
  @Input() reloadTick: string;
  @Output() OnDownload = new EventEmitter<ElementRef>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  reportSubscription: Subscription;
  dataSource: MatTableDataSource<EmailStats>;

  reportData: EmailStats[] = [];
  reportColumns: string[] = ['system', 'unprocessed', 'sent', 'failedOnce', 'deferred', 'error', 'attachments'];
  unprocessedTotal = 0;
  sentTotal = 0;
  errorTotal = 0;
  failedOnceTotal = 0;
  deferredTotal = 0;
  attachmentsTotal = 0;

  constructor(private reportingService: ReportingService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.reloadReport();
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reloadReport();
  }

  ngOnDestroy() {
    this.reportSubscription.unsubscribe();
  }

  reloadReport() {
    this.reportSubscription = this.reportingService
      .getEmailStats(this.getDate(this.fromDate, false), this.getDate(this.toDate, true))
      .subscribe((result: EmailStatsData) => {
        this.reportData = result.stats;
        this.dataSource = new MatTableDataSource<EmailStats>(this.reportData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filter = this.filter?.trim()?.toLowerCase();
        this.unprocessedTotal = 0;
        this.sentTotal = 0;
        this.errorTotal = 0;
        this.failedOnceTotal = 0;
        if (this.reportData && this.reportData?.length > 0) {
          this.unprocessedTotal = this.reportData?.map(item => item.unprocessed).reduce((prev, next) => prev + next, 0);
          this.sentTotal = this.reportData?.map(item => item.sent).reduce((prev, next) => prev + next, 0);
          this.errorTotal = this.reportData?.map(item => item.error).reduce((prev, next) => prev + next, 0);
          this.failedOnceTotal = this.reportData?.map(item => item.failedOnce).reduce((prev, next) => prev + next, 0);
          this.deferredTotal = this.reportData?.map(item => item.deferred).reduce((prev, next) => prev + next, 0);
          this.attachmentsTotal = this.reportData?.map(item => item.attachments).reduce((prev, next) => prev + next, 0);
        }
      }, (error) => {
        this.snackBar.open(`Could not retrieve email stat due to ${error.statusText || error.message}. Please try again later.`, 'Ok', { duration: 3000 });
      });
  }

  private getDate(date: string, inclusive: boolean): string {
    const dt = new Date(date);
    dt.setDate(dt.getDate() + (inclusive ? 1 : 0))
    return dt.toJSON();
  }

  downloadReport() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement); //converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `EmailStatsReport`);
    XLSX.writeFile(wb, `EmailService_Report_Email_Stats_From_${this.fromDate}_To_${this.toDate}_On_${this.datePipe.transform((new Date()), 'dd_MMM_yyyy_HH_mm')}.xlsx`);
  }
}
