// Refer https://github.com/vigneshsithirai/Angular-Interceptor more details.

import { ErrorDialogService } from './error-dialog.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorInfo } from '../models/error-info';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(public errorDialogService: ErrorDialogService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('http interceptor used');

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorInfo: ErrorInfo = new ErrorInfo();
        if (error?.error?.reason) {
          errorInfo.reason = error.error.reason;
        } else if (error?.error?.title) {
          errorInfo.reason = error.error.title;
        } else if (error?.status === 400 && error?.error?.isValidResponse === false) {
          // validation error
          errorInfo.isValidationError = true;
          errorInfo.reason = 'Validation error';
          errorInfo.validationErrors = error?.error?.errors;
        } else if (typeof error?.error === 'string') {
          errorInfo.reason = error.error;
        }
        errorInfo.status = error?.status;
        console.log('Error occured at:', request, error);
        if (request.headers.get('sg_ignoreError') !== 'true') {
          this.errorDialogService.openDialog(errorInfo);
        }
        return throwError(error);
      }));
  }
}
