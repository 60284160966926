import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { EmailSystemForEdit } from "../../../models/email-system-for-edit";
import { FeatureItem } from "../../models/feature";
import { FeatureService } from '../../services/feature.service';
import { ManagementService } from "../../services/management.service";

@Component({
  selector: 'system-edit',
  templateUrl: './system-edit.component.html',
  styleUrls: ['./system-edit.component.scss']
})
export class SystemEditComponent implements OnInit {
  id: number;
  system: EmailSystemForEdit = {
    id: 0,
    name: '',
    isEnabled: true,
    canEdit: true,
    createdDate: new Date(),
    callbackUrl: ''
  };

  canAccessManagement = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private featureService: FeatureService,
    private managementService: ManagementService) {
  }

  ngOnInit() {
    this.canAccessManagement = this.featureService?.canAccess(FeatureItem.SystemManagement);

    this.route.paramMap.subscribe(x => {
      const idText = x.get('id');
      if (idText) {
        this.id = parseInt(idText);
      }

      if (this.id) {
        this.managementService.getSystem(this.id).subscribe(s => {
          if (s.success) {
            this.system = s.system;
          } else {
            this.snackBar.open(s.message, 'Ok', { duration: 3000 });
          }
        });
      }
    });
  }

  save() {
    if (this.system.id) {
      this.managementService.updateSystem(this.system).subscribe(x => {
        this.showMessageAndBack(x.success ? `System updated.` : x.message);
      });
    } else {
      this.managementService.addSystem(this.system).subscribe(x => {
        this.showMessageAndBack(x.success ? `System added.` : x.message);
      });
    }
  }

  showMessageAndBack(message: string) {
    this.snackBar.open(message, 'Ok', { duration: 3000 }).afterDismissed().subscribe(() => {
      this.router.navigate(['/admin/systems']);
    });
  }
}
