<div class="errorContent">
    <div class="errorTitle" *ngIf="errorTitle.length > 0">Error! {{errorTitle}}</div>
    <div class="errorTitle" *ngIf="errorTitle.length === 0">Unexpected error!</div>
    <div *ngIf="errorTitle.length === 0" class="errorDescription">
        If there are no error details found in this message, please check if all the required services are available, or check the server logs for more information. 
    </div>
    <div *ngIf="isValidationError" class="errorDescription">
        <ul>
            <li *ngFor="let validationError of validationErrors">
                {{ validationError }}
            </li>
        </ul>
    </div>
    <div class="errorDescription">
        <span *ngIf="detailedInfo || errorMessage" >Review the error information <span class="clickText" (click)="toggleError()">(Click here to show / hide)</span> for more details. </span>
        Please try again and if the issue persists, contact your manager with the error information.
    </div>
    <div class="errorInfo" *ngIf="showJSON && detailedInfo">
        Troubleshooting information:
        <ngx-json-viewer [json]="detailedInfo" [expanded]="true"></ngx-json-viewer>
    </div> 
    <div class="errorInfo" *ngIf="showJSON && errorMessage">
        Troubleshooting information:
        <ngx-json-viewer [json]="errorMessage" [expanded]="true"></ngx-json-viewer>
    </div> 
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="closeDialog()">Close</button>
</mat-dialog-actions>