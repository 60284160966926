import { AppConfiguration } from 'src/config/app-configuration';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { RuntimeService } from './services/runtime.service';
import { Account, AuthError, Configuration, InteractionRequiredAuthError } from 'msal';
import { Inject } from '@angular/core';
import { BroadcastService, MsalService, MSAL_CONFIG } from '@azure/msal-angular';
import { TokenAutoRenewalService } from './services/token-auto-renewal.service';
import { NotificationService } from './services/notification-servive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'ApplicationTitle';
  user: Account;
  theme: string;
  avatarPhotoURL: any = '../assets/images/avatar.png';
  applicationName: string;

  constructor(
    @Inject(MSAL_CONFIG) private msalConfig: Configuration,
    private appConfig: AppConfiguration, private titleService: Title,
    private msalService: MsalService,
    private broadcastService: BroadcastService,
    private notificationService: NotificationService,
    private router: Router,
    private tokenAutoRenewalService: TokenAutoRenewalService,
    private runtime: RuntimeService) {
      this.title = appConfig.Configuration.ApplicationTitle;
      this.applicationName = appConfig.Configuration.ApplicationName;
      this.user = this.msalService.getAccount();
      this.msalService.acquireTokenSilent({
        scopes: [this.msalConfig.auth.clientId]
      })
      .catch((error: AuthError) => {
        if (InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
          this.msalService.getLogger().info("Interaction required error in MSAL Guard, prompting for interaction.");
          return this.msalService.loginRedirect();
        }

        this.msalService.getLogger().error(`Non-interaction error in token auto renewal service: ${error.errorMessage}`);
        throw error;
      });
    this.listenToLoginEvent();
  }

  private listenToLoginEvent() {
    this.broadcastService.subscribe('msal:acquireTokenSuccess', (payload) => {
      const account: Account = payload.account;
      this.runtime.token = payload.idToken.rawIdToken;
      this.tokenAutoRenewalService.setupTokenRenewal(payload.expiresOn);
      this.notificationService.relayUserName(account.userName);
    });
  }

  ngOnInit() {
    this.titleService.setTitle(`${this.title}`);
    this.theme = 'sgfleet-default-theme';
  }

  goInitiate() {
    this.router.navigate(['/app']);
  }

  goEditing() {
    this.router.navigate(['/search']);
  }
}
