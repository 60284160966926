import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { QueueStatus } from "../../../models/queue-status";
import { FeatureItem } from "../../models/feature";
import { FeatureService } from '../../services/feature.service';
import { ManagementService } from "../../services/management.service";

@Component({
  selector: 'service-management',
  templateUrl: './service-management.component.html',
  styleUrls: ['./service-management.component.scss']
})
export class ServiceManagementComponent implements OnInit {
  displayedColumns: string[] = ['name', 'status', 'defaultMessageTimeToLive'/*,'enableDeadLetteringOnMessageExpiration', 'autoDeleteOnIdle'*/, 'maxDeliveryCount', 'maxSizeInMB', 'messageCount', 'sizeInBytes', 'activeMessageCount', 'deadLetterMessageCount', 'scheduledMessageCount', 'transferDeadLetterMessageCount', 'transferMessageCount'];
  dataSource: MatTableDataSource<QueueStatus>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  messageCountTotal = 0;
  activeMessageCountTotal = 0;
  deadLetterMessageCountTotal = 0;
  scheduledMessageCountTotal = 0;
  transferDeadLetterMessageCountTotal = 0;
  transferMessageCountTotal = 0;
  sizeInBytesTotal = 0;
  maxSizeInMBTotal = 0;

  canAccessManagement = false;

  statusList = [
    { id: 'Active', name: 'Active' },
    { id: 'Disabled', name: 'Disabled' },
    { id: 'SendDisabled', name: 'Send disabled' },
    { id: 'ReceiveDisabled', name: 'Receive disabled' }
  ];

  constructor(
    private snackBar: MatSnackBar,
    private featureService: FeatureService,
    private managementService: ManagementService) {
  }

  ngOnInit() {
    this.canAccessManagement = this.featureService?.canAccess(FeatureItem.QueueManagement);

    this.loadQueueStatus();
  }

  loadQueueStatus() {
    this.managementService.getQueueStatus().subscribe(x => {
      if (x.success) {
        this.messageCountTotal = x.queues.map(item => item.messageCount).reduce((prev, next) => prev + next, 0);
        this.activeMessageCountTotal = x.queues.map(item => item.activeMessageCount).reduce((prev, next) => prev + next, 0);
        this.deadLetterMessageCountTotal = x.queues.map(item => item.deadLetterMessageCount).reduce((prev, next) => prev + next, 0);
        this.scheduledMessageCountTotal = x.queues.map(item => item.scheduledMessageCount).reduce((prev, next) => prev + next, 0);
        this.transferDeadLetterMessageCountTotal = x.queues.map(item => item.transferDeadLetterMessageCount).reduce((prev, next) => prev + next, 0);
        this.transferMessageCountTotal = x.queues.map(item => item.transferMessageCount).reduce((prev, next) => prev + next, 0);
        this.sizeInBytesTotal = x.queues.map(item => item.sizeInBytes).reduce((prev, next) => prev + next, 0);
        this.maxSizeInMBTotal = x.queues.map(item => item.maxSizeInMB).reduce((prev, next) => prev + next, 0);

        this.dataSource = new MatTableDataSource<QueueStatus>(x.queues);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.snackBar.open(x.message, 'Ok', { duration: 3000 });
      }
    });
  }

  changeStatus(queue: QueueStatus) {
    this.managementService.changeQueueStatus(queue).subscribe(x => {
      this.snackBar.open(x.success ? `Queue status changed.` : x.message, 'Ok', { duration: 3000 });
    });
  }
}
