import { Subscription } from 'rxjs';
import { Moment } from 'moment';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { User } from 'src/app/admin/models/user';
import { LocalStorageKeys, LocalStorageService } from 'src/app/services/local-storage.service';
import { FeatureService } from 'src/app/admin/services/feature.service';
import { FeatureItem } from 'src/app/admin/models/feature';

const monthNames = ["January", "February", "March", "April", "May", "June"
  , "July", "August", "September", "October", "November", "December"];
@Component({
  selector: 'app-report-main-pane',
  templateUrl: './report-main-pane.component.html',
  styleUrls: ['./report-main-pane.component.scss']
})
export class ReportMainPaneComponent implements OnInit {
  userName: string;
  userNameSubscription: Subscription;
  fromDate: FormControl;
  toDate: FormControl;
  formattedFromDate: string;
  formattedToDate: string;
  filterValue: string = '';
  reloadTick: string;
  user: User;

  canAccessReports = false;

  constructor(private localStorageService: LocalStorageService,
    private featureService: FeatureService) {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.fromDate = new FormControl(moment(`${year}/${monthNames[month].substring(0, 3)}/01`, 'YYYY/MMM/DD'));
    this.toDate = new FormControl(moment(`${year}/${monthNames[month].substring(0, 3)}/${day}`, 'YYYY/MMM/DD'));
    this.onDateChange();
  }

  ngOnInit(): void {
    this.reloadUser();
  }

  onDateChange() {
    this.reloadUser();
    this.formattedFromDate = this.getDateInFormat(this.fromDate.value);
    this.formattedToDate = this.getDateInFormat(this.toDate.value);
  }

  reloadUser() {
    this.user = this.localStorageService.get<User>(LocalStorageKeys.User);
    this.userName = this.user?.emailAddress;

    this.canAccessReports = this.featureService?.canAccess(FeatureItem.Reports);
  }

  getDateInFormat(value: Moment) {
    try {
      if (value) return value.format('DD/MMM/YYYY');
    }
    catch { }
    return null;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
  }

  reloadReports() {
    this.reloadUser();
    this.reloadTick = Date.now().toString();
  }
}
