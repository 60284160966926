<div class="dashboard" *ngIf="canAccessManagement">
  <div class="title">Management | System</div>

  <div class="parent">
    <div class="mat-form-field margin-top">
      <mat-label class="margin-right">Enabled</mat-label>
      <mat-checkbox color="primary" [(ngModel)]="system.isEnabled">
      </mat-checkbox>
    </div>
    <mat-form-field class="margin-top">
      <mat-label>Name</mat-label>
      <input matInput placeholder="Name" [(ngModel)]="system.name" name="name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Callback url</mat-label>
      <input matInput placeholder="Callback url" [(ngModel)]="system.callbackUrl" name="callbackUrl">
    </mat-form-field>
  </div>

  <div class="space-commands">
    <button mat-button class="btn-nav" (click)="save()">Save</button>
  </div>
</div>
