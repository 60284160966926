<mat-toolbar class="navbar" [class.mat-elevation-z6]="true">
  <div>
    <a class="sgflogo" [routerLink]="[this.homepageLink]" (click)="navigateToHome()">
      <img src="../assets/images/sgfleetlogo.png">
    </a>
    <span class="separator">|</span>
    <span class="applicationName">{{applicationName}}</span>
    <span *ngIf="environmentText" class="header-env env-{{environmentText | lowercase}}">
      | {{environmentText}}
    </span>
  </div>
  <div>
    <span matTooltip="Settings" class="icons" [matMenuTriggerFor]="menu">
      <i class="material-icons">settings</i>
    </span>
    <mat-menu #menu="matMenu">
      <button *ngIf="canAccessAdmin" mat-menu-item [matMenuTriggerFor]="admin">Admin</button>
      <button *ngIf="canAccessRules" mat-menu-item [matMenuTriggerFor]="rules">Rules</button>
      <button *ngIf="canAccessReports" mat-menu-item [matMenuTriggerFor]="reports">Reports</button>
      <button mat-menu-item (click)="openVersionDialog()">About</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
    <mat-menu #admin="matMenu">
      <button *ngIf="canAccessQueueManagement" mat-menu-item (click)="navigateTo('admin/service')">Queue management</button>
      <button *ngIf="canAccessSystemManagement" mat-menu-item (click)="navigateTo('admin/systems')">System management</button>
      <button *ngIf="canAccessAdmin" mat-menu-item (click)="navigateTo('version')">Version</button>
    </mat-menu>
    <mat-menu #reports="matMenu">
      <button *ngIf="canAccessReports" mat-menu-item (click)="navigateTo('reports')">Email stats</button>
    </mat-menu>
    <mat-menu #rules="matMenu">
      <button *ngIf="canAccessRules" mat-menu-item (click)="navigateTo('rules')">Subscription</button>
    </mat-menu>
    <span class="profileName">{{account?.name}}</span>
    <span matTooltip="{{account?.userName}}" class="avatar"><img class="avatarImage" [src]="avatarPhotoURL"></span>
  </div>
</mat-toolbar>
