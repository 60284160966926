<div class="dashboard" *ngIf="canAccessRules">
  <div class="title">Management | Rules</div>

  <div class="mat-elevation-z8 margin-bottom">
    <table mat-table matSort [dataSource]="dataSource" class="full-width">
      <tr style="display:none!important">
        
        <ng-container matColumnDef="system">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> System </th>
          <td mat-cell *matCellDef="let element">{{element.system}}</td>
        </ng-container>

        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Priority </th>
          <td mat-cell *matCellDef="let element">{{element.priority}}</td>
        </ng-container>

        <ng-container matColumnDef="isEnabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </th>
          <td mat-cell *matCellDef="let element">{{element.isEnabled ? "Yes" : "No"}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="redirectAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Redirect address </th>
          <td mat-cell *matCellDef="let element">{{element.redirectAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="senderAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sender address </th>
          <td mat-cell *matCellDef="let element">{{element.senderAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="receiverAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Receiver address </th>
          <td mat-cell *matCellDef="let element">{{element.receiverAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="ccAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> CC address </th>
          <td mat-cell *matCellDef="let element">{{element.ccAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject </th>
          <td mat-cell *matCellDef="let element">{{element.subject}}</td>
        </ng-container>

        <ng-container matColumnDef="body">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Body </th>
          <td mat-cell *matCellDef="let element">{{element.body}}</td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created date </th>
          <td mat-cell *matCellDef="let element">{{element.createdDate | date:'d/MM/yyyy hh:mm:ss a'}}</td>
        </ng-container>

        <ng-container matColumnDef="updatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated date </th>
          <td mat-cell *matCellDef="let element">{{element.updatedDate | date:'d/MM/yyyy hh:mm:ss a'}}</td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/rule', element.id]" title="edit" *ngIf="element.canEdit"><i class="material-icons">edit</i></a>
            <a [routerLink]="" (click)="deleteRule(element)" title="delete" *ngIf="element.canEdit"><i class="material-icons">delete</i></a>
          </td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'status-enable': row.isEnabled, 'status-disabled': !row.isEnabled}"></tr>
    </table>
  </div>

  <div class="space-commands">
    <button mat-button class="btn-nav" (click)="addRule()">Add rule</button>
  </div>
</div>
