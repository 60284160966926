import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { FeatureItem } from "../../../admin/models/feature";
import { FeatureService } from '../../../admin/services/feature.service';
import { RuleService } from "../../services/rule.service";
import { EmailRule } from "../../models/email-rule";

@Component({
  selector: 'rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
  displayedColumns: string[] = ['system', 'priority', 'isEnabled', 'redirectAddress', 'senderAddress', 'receiverAddress', 'ccAddress', 'subject', 'body', 'createdDate', 'updatedDate', 'edit'];
  dataSource: MatTableDataSource<EmailRule>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  canAccessRules = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private featureService: FeatureService,
    private ruleService: RuleService) {
  }

  ngOnInit() {
    this.canAccessRules = this.featureService?.canAccess(FeatureItem.Rules);

    this.loadRules();
  }

  loadRules() {
    this.ruleService.getRules().subscribe(x => {
      if (x.success) {
        for (let rule of x.rules) {
          rule.canEdit = this.featureService?.user.emailAddress.toLowerCase() === rule.userId.toLowerCase();
        }
        this.dataSource = new MatTableDataSource<EmailRule>(x.rules);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.snackBar.open(x.message, 'Ok', { duration: 3000 });
      }
    });
  }

  addRule() {
    this.router.navigate(['/rule']);
  }

  deleteRule(rule: EmailRule) {
    this.ruleService.deleteRule(rule.id).subscribe(x => {
      this.loadRules();
    });
  }
}
