import { Component, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailService } from '../services/email.service';
import { Email } from '../models/email';
import { Account } from 'msal';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  account: Account;
  displayedColumns: string[] = ['id', 'system', 'senderName', 'senderAddress', 'subject', 'referenceId', 'createdDate', 'receiverName', 'receiverAddress', 'attachmentCount', 'status'];
  dataSource: MatTableDataSource<Email>;
  filterType: string = '1';
  count: number = 50;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private snackBar: MatSnackBar,
    private emailService: EmailService,
    private route: ActivatedRoute,
    private router: Router,
    private msalService: MsalService,
    @Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit() {
    const roles = this.msalService.getAccount().idTokenClaims['roles'];
    if (!roles || roles.length == 0) {
      this.router.navigate(['/access-denied']);
      return;
    }

    this.route.paramMap.subscribe(x => {
      const count = x.get('count');
      this.count = count !== null ? parseInt(count) : this.count;
      this.load();
    });
  }

  filterTypeChange(event) {
    this.filterType = event.value;
    this.load();
  }

  load() {
    this.emailService.getRecentEmails(this.filterType, this.count).subscribe(e => {
      if (e.success) {
        for (let email of e.emails) {
          email.createdDateLocal = new Date(email.createdDate).toLocaleString(this.locale);
        }
        this.dataSource = new MatTableDataSource<Email>(e.emails);
        this.dataSource.sort = this.sort;
      } else {
        this.snackBar.open(e.message, 'Ok', { duration: 3000 });
      }
    }, (error) => {
      if (error.error.statusCode === 401)
        this.router.navigate(['/app']);
      else if (error.status !== 200)
        this.snackBar.open(`Could not load email data due to ${error.statusText || error.message}. Please try again later.`, 'Ok', { duration: 3000 });
    });
  }
}
