import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/config/app-configuration';
import { Injectable } from '@angular/core';
import { EmailStatsData } from '../models/email-stats-data';
import { RuntimeService } from '../../services/runtime.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  reportURL: string;
  constructor(private appConfig: AppConfiguration,
    private runtime: RuntimeService,
    private http: HttpClient) {
    this.reportURL = `${this.appConfig?.Configuration?.EmailServiceApiUrl}/v1.0/management`;
  }

  getEmailStats(fromDate: string, toDate: string) {
    return this.http.post<EmailStatsData>(
      `${this.reportURL}/email-stats`,
      JSON.stringify({ fromDate: fromDate, toDate: toDate}),
      this.runtime.getHttpOptions());
  }
}
