import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private userNameBehavior = new BehaviorSubject<string>('');
  userName = this.userNameBehavior.asObservable();

  relayUserName(value: string) {
    this.userNameBehavior.next(value);
  }
}
