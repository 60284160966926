// Refer https://github.com/vigneshsithirai/Angular-Interceptor more details.

import { ErrorPageComponent } from './../error-page/error-page.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable, EventEmitter } from '@angular/core';
import { ErrorInfo } from '../models/error-info';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  public isDialogOpen = false;
  public dialogClosed = new EventEmitter();

  constructor(public dialog: MatDialog) { }

  openDialog(data: ErrorInfo): any {
    if (this.isDialogOpen) {
      return false;
    }
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(ErrorPageComponent, {
      width: '700px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      this.dialogClosed.emit();
    });
  }
}
