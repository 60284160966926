import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { EmailRule } from "../../models/email-rule";
import { FeatureService } from '../../../admin/services/feature.service';
import { FeatureItem } from "../../../admin/models/feature";
import { RuleService } from "../../services/rule.service";
import { ManagementService } from "../../../admin/services/management.service";
import { EmailSystem } from "../../../models/email-system";

@Component({
  selector: 'rule-edit',
  templateUrl: './rule-edit.component.html',
  styleUrls: ['./rule-edit.component.scss']
})
export class RuleEditComponent implements OnInit {
  id: string;
  rule: EmailRule = {
    id: '',
    userId: '',
    redirectAddress: '',
    isEnabled: true,
    createdDate: new Date(),
    priority: 1,
    systemId: null
  };

  canAccessRules = false;
  systems: EmailSystem[];

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private managementService: ManagementService,
    private featureService: FeatureService,
    private ruleService: RuleService) {
  }

  ngOnInit() {
    this.canAccessRules = this.featureService?.canAccess(FeatureItem.Rules);

    this.route.paramMap.subscribe(x => {
      this.id = x.get('id');

      this.managementService.getSystems().subscribe(s => {
        if (s.success) {
          this.systems = [{ id: 0, name: 'None' }, ...s.systems];

          if (this.id) {
            this.ruleService.getRule(this.id).subscribe(r => {
              if (r.success) {
                this.rule = r.rule;
              } else {
                this.snackBar.open(r.message, 'Ok', { duration: 3000 });
              }
            });
          }
        } else {
          this.snackBar.open(s.message, 'Ok', { duration: 3000 });
        }
      });
    });
  }

  save() {
    if (!this.rule.body && !this.rule.subject && !this.rule.senderAddress && !this.rule.ccAddress && !this.rule.receiverAddress && !this.rule.systemId) {
      this.snackBar.open(`Please specify at least one match criteria.`, 'Ok', { duration: 3000 });
      return;
    }

    if (!this.rule.redirectAddress) {
      this.snackBar.open(`Please specify the redirect address.`, 'Ok', { duration: 3000 });
      return;
    }

    if (this.rule.id) {
      this.ruleService.updateRule(this.rule).subscribe(x => {
        this.showMessageAndBack(x.success ? `Rule updated.` : x.message);
      });
    } else {
      this.rule.id = null;
      this.ruleService.addRule(this.rule).subscribe(x => {
        this.showMessageAndBack(x.success ? `Rule added.` : x.message);
      });
    }
  }

  showMessageAndBack(message: string) {
    this.snackBar.open(message, 'Ok', { duration: 3000 }).afterDismissed().subscribe(() => {
      this.router.navigate(['/rules']);
    });
  }
}
