<div class="title">Email details</div>
<mat-spinner diameter="36" *ngIf="!result.email"></mat-spinner>
<div *ngIf="result && result.email">
  <div class="item">
    <div class="item-header">Sender:</div>
    <div class="item-details" [innerHtml]="result.formattedSender"></div>
  </div>
  <div class="item">
    <div class="item-header">Receiver:</div>
    <div class="item-details" [innerHtml]="result.formattedReceiver"></div>
  </div>
  <div class="item">
    <div class="item-header">CC Address:</div>
    <div class="item-details" [innerHtml]="result.formattedCC"></div>
  </div>
  <div class="item">
    <div class="item-header">BCC Address:</div>
    <div class="item-details" [innerHtml]="result.formattedBCC"></div>
  </div>
  <div class="item">
    <div class="item-header">Reply Address:</div>
    <div class="item-details" [innerHtml]="result.formattedReply"></div>
  </div>
  <div class="item">
    <div class="item-header">Subject:</div>
    <div class="item-details">{{result.email.subject}}</div>
  </div>
  <div class="item">
    <div class="item-header">Created Date:</div>
    <div class="item-details">{{result.email.createdDate | date:'d/MM/yyyy hh:mm:ss a'}}</div>
  </div>
  <div class="item">
    <div class="item-header">Source System:</div>
    <div class="item-details">{{result.email.system}}</div>
  </div>
  <div class="item">
    <div class="item-header">Importance:</div>
    <div class="item-details">{{result.email.importance}}</div>
  </div>
  <div class="item">
    <div class="item-header">Status:</div>
    <div class="item-details">{{result.email.status}}</div>
  </div>
  <div class="item">
    <div class="item-header">Reference Id:</div>
    <div class="item-details">{{result.email.referenceId}}</div>
  </div>
  <div class="item">
    <div class="item-header">Attachments:</div>
    <div class="attachments">
      <div *ngFor="let attachment of attachments; trackBy: trackItem">
        <a [routerLink]="" (click)="downloadAttachment(attachment)">{{attachment.name}} ({{attachment.size | fileSize}})</a>
      </div>
    </div>
  </div>
  <div class="item">
    <div class="item-header">Content:</div>
    <iframe class="email-body" [srcdoc]="result.body"></iframe>
  </div>
</div>

<div class="title">Logs</div>
<div class="mat-elevation-z8 margin-top" *ngIf="canAccessLogs && result && result.logs">
  <table mat-table matSort [dataSource]="dataSource" class="full-width">
    <tr style="display:none!important">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Reason </th>
        <td mat-cell *matCellDef="let element">{{element.reason}}</td>
      </ng-container>

      <ng-container matColumnDef="correlationId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlation Id </th>
        <td mat-cell *matCellDef="let element">{{element.transactionId}}</td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
        <td mat-cell *matCellDef="let element">{{element.createdDate | date:'d/MM/yyyy hh:mm:ss a'}}</td>
      </ng-container>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="space-commands">
  <button mat-button class="btn-nav" (click)="home()">Back</button>
  <button mat-button class="btn-nav" (click)="resend()" *ngIf="canResend">Resend</button>
</div>
