import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { EmailSystemForEdit } from "../../../models/email-system-for-edit";
import { FeatureItem } from "../../models/feature";
import { FeatureService } from '../../services/feature.service';
import { ManagementService } from "../../services/management.service";

@Component({
  selector: 'systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'isEnabled', 'callbackUrl', 'createdDate', 'updatedDate', 'edit'];
  dataSource: MatTableDataSource<EmailSystemForEdit>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  canAccessManagement = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private featureService: FeatureService,
    private managementService: ManagementService) {
  }

  ngOnInit() {
    this.canAccessManagement = this.featureService?.canAccess(FeatureItem.SystemManagement);

    this.managementService.getSystemsForEdit().subscribe(x => {
      if (x.success) {
        this.dataSource = new MatTableDataSource<EmailSystemForEdit>(x.systems);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.snackBar.open(x.message, 'Ok', { duration: 3000 });
      }
    });
  }

  addSystem() {
    this.router.navigate(['/admin/system']);
  }
}
