<table [class.push-down]="!versionDialogMode">
  <tr>
    <td>UI Version: </td>
    <td>
      {{Version.build}}
      <span *ngIf="versionDialogMode">({{Version.commitTime | date: 'd/MM/yyyy'}})</span>
    </td>
  </tr>
  <tr *ngIf="!versionDialogMode">
    <td>UI Commit Date: </td>
    <td>{{Version.commitTime}}</td>
  </tr>
  <tr *ngIf="!versionDialogMode">
    <td>UI Revision: </td>
    <td>{{Version.revision}}</td>
  </tr>
  <tr>
    <td>API: </td>
    <td>{{apiVer}}</td>
  </tr>
  <tr *ngIf="!versionDialogMode">
    <td>API Revision: </td>
    <td>{{apiRev}}</td>
  </tr>
</table>
