import { Component, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { Email } from '../models/email';
import { EmailService } from '../services/email.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailFilter } from '../models/email-filter';
import { EmailSystem } from '../models/email-system';
import { ManagementService } from '../admin/services/management.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent {
  displayedColumns: string[] = ['id', 'system', 'senderName', 'senderAddress', 'subject', 'referenceId', 'createdDate', 'receiverName', 'receiverAddress', 'attachmentCount', 'status'];
  dataSource: MatTableDataSource<Email>;
  receiverAddress: string = '';
  senderAddress: string = '';
  subject: string = '';
  referenceId: string = '';
  systems: EmailSystem[];
  selectedSystem = 0;
  count: number = 100;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private emailService: EmailService,
    private managementService: ManagementService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private msalService: MsalService,
    @Inject(LOCALE_ID) public locale: string) {

    const roles = this.msalService.getAccount().idTokenClaims['roles'];
    if (!roles || roles.length == 0) {
      this.router.navigate(['/access-denied']);
      return;
    }

    this.route.paramMap.subscribe(x => {
      const count = x.get('count');
      this.count = count !== null ? parseInt(count) : this.count;

      this.receiverAddress = x.get('receiverAddress');
      this.senderAddress = x.get('senderAddress');
      this.referenceId = x.get('referenceId');
      this.managementService.getSystems().subscribe(s => {
        if (s.success) {
          this.systems = [{ id: 0, name: '(Select)' }, ...s.systems];
        } else {
          this.snackBar.open(s.message, 'Ok', { duration: 3000 });
        }
      });
    });
  }

  search() {
    if (this.selectedSystem === 0) {
      this.snackBar.open(`Please select the system.`, 'Ok', { duration: 3000 });
      return;
    }

    const filter = {
      systemId: this.selectedSystem,
      subject: this.subject,
      referenceId: this.referenceId,
      receiverAddress: this.receiverAddress,
      senderAddress: this.senderAddress,
      count: this.count
    } as EmailFilter;

    this.emailService.findEmails(filter).subscribe(x => {
      if (x.success) {
        for (let email of x.emails) {
          email.createdDateLocal = new Date(email.createdDate).toLocaleString(this.locale);
        }
        this.dataSource = new MatTableDataSource<Email>(x.emails);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        if (x.emails.length === 0)
          this.snackBar.open(`No emails found.`, 'Ok', { duration: 3000 });
      } else {
        this.snackBar.open(x.message, 'Ok', { duration: 3000 });
      }
    }, (error) => {
      if (error.error.statusCode === 401)
        this.router.navigate(['/app']);
      else
        this.snackBar.open(`Could not retrieve email data due to ${error.statusText || error.message}. Please try again later.`, 'Ok', { duration: 3000 });
    });
  }
}
