import { AppConfiguration } from 'src/config/app-configuration';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Account } from 'msal';
import { MatDialog } from '@angular/material/dialog';
import { VersionDialogComponent } from '../version-dialog/version-dialog.component';
import { FeatureItem } from '../admin/models/feature';
import { LocalStorageKeys, LocalStorageService } from '../services/local-storage.service';
import { FeatureService } from '../admin/services/feature.service';
import { AzureRoleItem } from '../admin/models/azure-role';
import { SessionStorageService } from '../services/session-storage.service';
import { EmailService } from '../services/email.service';
import { RuntimeService } from '../services/runtime.service';
import { NotificationService } from '../services/notification-servive';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})

export class NavBarComponent implements OnInit {
  account: Account;
  avatarPhotoURL: any = '../assets/images/avatar.png';
  applicationName: string;
  environmentText: string;
  environmentTextColour: string;
  homepageLink = '/';
  notificationsCount = 0;
  notificationToggleStatus = 0;

  canAccessQueueManagement = false;
  canAccessSystemManagement = false;
  canAccessAdmin = false;
  canAccessReports = false;
  canAccessRules = false;

  constructor(
    private msalService: MsalService,
    private sanitizer: DomSanitizer,
    private appConfig: AppConfiguration,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private featureService: FeatureService,
    private sessionStorageService: SessionStorageService,
    private emailService: EmailService,
    private notificationService: NotificationService,
    private runtime: RuntimeService,
    private router: Router) {
  }

  private loadProfileImage() {
    this.emailService.getAvatar().subscribe((baseImage: any) => {
      this.createImageFromBlob(baseImage);
    },
      error => {
        console.log('Error accessing user photo. Please verify if a photo exists for the user.', error);
      });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const result: any = reader.result;
      this.avatarPhotoURL = this.sanitizer.bypassSecurityTrustUrl(result);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  ngOnInit() {
    this.account = this.msalService.getAccount();

    this.applicationName = this.appConfig?.Configuration?.ApplicationName;
    if (this.appConfig?.Configuration?.HeaderEnvText && this.appConfig.Configuration.HeaderEnvText.trim() != "") {
      this.environmentText = this.appConfig.Configuration.HeaderEnvText;
    }

    const user =
    {
      userId: this.account.userName,
      emailAddress: this.account.userName,
      fullName: this.account.name,
      azureRolesFeatures: this.account.idTokenClaims['roles'] ? (this.account.idTokenClaims['roles'] as unknown as string[]).map(x => {
        return {
          isEnabled: true,
          name: x,
          isAdmin: x === AzureRoleItem.Admin
        }
      }) : []
    };
    this.localStorageService.set(LocalStorageKeys.Features, [{
      isEnabled: true,
      name: FeatureItem.QueueManagement,
      featureId: 1,
      azureRoles: [{
        name: AzureRoleItem.Admin,
      }]
    },
    {
      isEnabled: true,
      name: FeatureItem.SystemManagement,
      featureId: 1,
      azureRoles: [{
        name: AzureRoleItem.Admin,
      },
      {
        name: AzureRoleItem.SuperUser,
      }]
    },
    {
      isEnabled: true,
      name: FeatureItem.Reports,
      featureId: 2,
      azureRoles: [{
        name: AzureRoleItem.Admin,
      },
      {
        name: AzureRoleItem.ReportViewer,
      },
      {
        name: AzureRoleItem.SuperUser,
      }]
    },
    {
      isEnabled: true,
      name: FeatureItem.Logs,
      featureId: 3,
      azureRoles: [{
        name: AzureRoleItem.Admin,
      },
      {
        name: AzureRoleItem.SuperUser,
      }]
    },
    {
      isEnabled: true,
      name: FeatureItem.Rules,
      featureId: 4,
      azureRoles: [{
        name: AzureRoleItem.Admin,
      },
      {
        name: AzureRoleItem.SuperUser,
      }]
    }]);
    this.localStorageService.set(LocalStorageKeys.User, user);
    this.canAccessQueueManagement = this.featureService?.canAccess(FeatureItem.QueueManagement);
    this.canAccessSystemManagement = this.featureService?.canAccess(FeatureItem.SystemManagement);
    this.canAccessAdmin = this.canAccessQueueManagement || this.canAccessSystemManagement;
    this.canAccessReports = this.featureService?.canAccess(FeatureItem.Reports);
    this.canAccessRules = this.featureService?.canAccess(FeatureItem.Rules);

    this.notificationService.userName.subscribe(x => {
      if (this.runtime.token) {
        //todo:
        this.loadProfileImage();
      }
    });
  }

  navigateToHome() {
    this.router.navigateByUrl(this.homepageLink);
  }

  openVersionDialog() {
    this.dialog.open(VersionDialogComponent);
  }

  navigateTo(page: string) {
    this.router.navigate([page]);
  }

  clearLocalCache() {
    this.sessionStorageService.clearAll();
    this.localStorageService.removeLocalStorageKeys();
  }

  logout() {
    this.clearLocalCache();
    this.msalService.logout();
  }

}
